import { Text } from "@clipboard-health/ui-react";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import { Slider, Stack } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences/types";
import pluralize from "pluralize";
import { useController, type UseControllerProps } from "react-hook-form";

type DistanceField = Pick<AgentPreferences, "distance">;

type OpenShiftDistanceFieldProps<T extends DistanceField> = UseControllerProps<T>;

export function OpenShiftDistanceField<T extends DistanceField>(
  props: OpenShiftDistanceFieldProps<T>
) {
  const { name, control } = props;
  const { field } = useController<T>({
    name,
    control,
  });

  // TODO add useId from react 18 once upgraded
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <HomeIcon fontSize="medium" />
        <Text bold variant="h2">
          {field.value} {pluralize("mile", field.value)}
        </Text>
        <BusinessIcon fontSize="medium" />
      </Stack>
      <Slider
        valueLabelDisplay="auto"
        aria-label="Distance"
        step={1}
        min={1}
        max={150}
        value={field.value}
        data-testid="open-shift-filter-distance-slider"
        onChange={(_: Event, newValue: number | number[]) => {
          field.onChange(newValue as number);
        }}
      />
    </>
  );
}
