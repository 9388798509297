import constate from "constate";
import { useRef } from "react";

import { useMonthRange } from "../lib/Calendar/useMonthRange";

function useOpenShift() {
  const dateRef = useRef(new Date());
  const currentDate = dateRef.current;
  const { dateRange, onPrevious, onNext } = useMonthRange({
    startDate: currentDate,
  });

  return {
    currentDate,
    dateRange,
    onPrevious,
    onNext,
  };
}

export const [OpenShiftProvider, useOpenShiftContext] = constate(useOpenShift);
