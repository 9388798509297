import { Text } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences/types";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { OpenShiftDistanceField } from "./DistanceField";

type DistanceFieldValues = Pick<AgentPreferences, "distance">;

interface FormProps {
  onSubmit: (values: DistanceFieldValues) => void;
  defaultValues: DistanceFieldValues;
  closeModal: () => void;
}

export const openShiftCalendarDistanceFilterSchema = z.object({
  distance: z.number(),
});

export function DistanceDialogForm(props: FormProps) {
  const { onSubmit, defaultValues, closeModal } = props;

  const formMethods = useForm<DistanceFieldValues>({
    defaultValues,
    resolver: zodResolver(openShiftCalendarDistanceFilterSchema),
  });
  const { control, handleSubmit, formState } = formMethods;

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      onSubmit={handleSubmit((formData) => {
        onSubmit({ distance: formData.distance });
        closeModal();
      })}
    >
      <DialogTitle>Update your distance preference</DialogTitle>

      <DialogContent dividers>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          marginBottom={2}
          paddingX={{
            xs: 1,
            sm: 2,
            md: 4,
          }}
        >
          <Text bold textAlign="center">
            How far are you willing to travel for work?
          </Text>
          <OpenShiftDistanceField name="distance" control={control} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          size="large"
          disabled={formState.isSubmitting}
        >
          Apply
        </Button>
      </DialogActions>
    </Box>
  );
}
