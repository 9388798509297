import { MenuItem, Select } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences/types";
import { type AgentLicenseData } from "@src/appV2/Agents/types";
import { useController, type UseControllerProps } from "react-hook-form";

type LicenseField = Pick<AgentPreferences, "license">;

interface OpenShiftLicenseFieldProps<T extends LicenseField> extends UseControllerProps<T> {
  agentLicenses: AgentLicenseData[];
}

export function OpenShiftLicenseField<T extends LicenseField>(
  props: OpenShiftLicenseFieldProps<T>
) {
  const { name, control, agentLicenses } = props;
  const { field } = useController<T>({
    name,
    control,
  });

  // TODO add useId from react 18 once upgraded
  return (
    <Select fullWidth {...field} aria-label="License">
      {agentLicenses.map((license) => {
        return (
          <MenuItem key={license._id} value={license.qualification}>
            {license.qualification}
          </MenuItem>
        );
      })}
    </Select>
  );
}
