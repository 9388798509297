import { Text } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences/types";
import { type AgentLicenseData } from "@src/appV2/Agents/types";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { OpenShiftLicenseField } from "./LicenseField";

type LicenseFieldValues = Pick<AgentPreferences, "license">;

interface FormProps {
  agentLicenses: AgentLicenseData[];
  onSubmit: (values: LicenseFieldValues) => void;
  defaultValues: LicenseFieldValues;
  closeModal: () => void;
}

export const openShiftCalendarLicenseFilterSchema = z.object({
  license: z.string(),
});

export function LicenseDialogForm(props: FormProps) {
  const { agentLicenses, onSubmit, defaultValues, closeModal } = props;

  const sortedUniqueAgentLicenses = agentLicenses
    .filter(
      (value, index, self) =>
        self.findIndex((license) => license.qualification === value.qualification) === index
    )
    .sort((licenseA, licenseB) => licenseA.qualification.localeCompare(licenseB.qualification));

  const formMethods = useForm<AgentPreferences>({
    defaultValues,
    resolver: zodResolver(openShiftCalendarLicenseFilterSchema),
  });
  const { control, handleSubmit, formState } = formMethods;

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      onSubmit={handleSubmit((formData) => {
        onSubmit({ license: formData.license });
        closeModal();
      })}
    >
      <DialogTitle>Update your license preference</DialogTitle>

      <DialogContent dividers>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          marginBottom={2}
          paddingX={{
            xs: 1,
            sm: 2,
            md: 4,
          }}
        >
          <Text bold textAlign="center">
            Which license do you want to book shifts for?
          </Text>
          <OpenShiftLicenseField
            name="license"
            control={control}
            agentLicenses={sortedUniqueAgentLicenses}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          size="large"
          disabled={formState.isSubmitting}
        >
          Apply
        </Button>
      </DialogActions>
    </Box>
  );
}
