import { Box, Skeleton } from "@mui/material";
import { CalendarCellItem } from "@src/appV2/lib/Calendar";
import { type OpenShiftDay } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import { ShiftPeriod, ShiftWindow } from "@src/appV2/Shifts/Shift/types";

import { ShiftNameChip } from "./Chip";

interface ShiftNameCellProps {
  date: Date;
  openShiftsForDay: OpenShiftDay | undefined;
  agentShiftsForDay: Record<ShiftPeriod, number> | undefined;
  isLoading: boolean;
  isDisabled: boolean;
}

export function ShiftNameCell(props: ShiftNameCellProps) {
  const { date, openShiftsForDay, agentShiftsForDay, isLoading, isDisabled } = props;
  const { isWorkerAteamEnabled } = useATeamStatus();

  return (
    <CalendarCellItem date={date} isDisabled={isDisabled}>
      <Box
        sx={{
          paddingX: {
            xs: 0.125,
            sm: 0.25,
            md: 0.5,
          },
          rowGap: {
            xs: 0.125,
            sm: 0.25,
          },
          columnGap: {
            xs: 0.125,
            sm: 0.25,
          },
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {Object.values(ShiftPeriod).map((shiftPeriod) => {
          const openShiftsCount = openShiftsForDay?.[shiftPeriod] ?? 0;
          const agentShiftsCount = agentShiftsForDay?.[shiftPeriod] ?? 0;
          const hasPriorityAccessShift =
            openShiftsForDay?.priorityAccess?.includes(shiftPeriod) ?? false;

          const isShiftATeamWindowed =
            (isWorkerAteamEnabled &&
              openShiftsForDay?.windows?.[ShiftWindow.A_TEAM]?.includes(shiftPeriod)) ??
            false;

          if (isLoading) {
            return (
              <Skeleton key={shiftPeriod}>
                <ShiftNameChip
                  isDisabled
                  shiftPeriod={shiftPeriod}
                  openShiftsCount={0}
                  agentShiftsCount={0}
                  hasPriorityAccessShift={false}
                  isShiftATeamWindowed={isShiftATeamWindowed}
                />
              </Skeleton>
            );
          }

          return (
            <ShiftNameChip
              key={shiftPeriod}
              shiftPeriod={shiftPeriod}
              openShiftsCount={openShiftsCount}
              agentShiftsCount={agentShiftsCount}
              isDisabled={isDisabled}
              hasPriorityAccessShift={hasPriorityAccessShift}
              isShiftATeamWindowed={isShiftATeamWindowed}
            />
          );
        })}
      </Box>
    </CalendarCellItem>
  );
}
