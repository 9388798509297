import { Span } from "@clipboard-health/ui-react";
import { Skeleton, Stack } from "@mui/material";
import { CalendarCellItem } from "@src/appV2/lib/Calendar";
import { ShiftPeriod, ShiftPeriodContent } from "@src/appV2/Shifts/Shift/types";
import pluralize from "pluralize";

import { type OpenShiftCellProps } from "../types";

export function OpenShiftHeatMapCell(props: OpenShiftCellProps) {
  const { date, openShiftsForDay, agentShiftsForDay, isLoading, isDisabled } = props;

  return (
    <CalendarCellItem date={date} isDisabled={isDisabled}>
      <Stack
        direction="row"
        width="100%"
        paddingX={{
          xs: 0.5,
          sm: 2,
          md: 4,
        }}
      >
        {Object.values(ShiftPeriod).map((shiftPeriod) => {
          const openShiftsCount = openShiftsForDay?.[shiftPeriod] ?? 0;
          const agentShiftsCount = agentShiftsForDay?.[shiftPeriod] ?? 0;
          const hasOpenShiftsCount = openShiftsCount > 0;
          const hasAgentShiftsCount = agentShiftsCount > 0;
          if (isLoading) {
            return (
              <Skeleton key={shiftPeriod}>
                <Span
                  sx={{
                    display: "block",
                    width: "calc(100%/3)",
                    height: 8,
                  }}
                />
              </Skeleton>
            );
          }

          return (
            <Span
              key={shiftPeriod}
              sx={{
                display: "block",
                width: "calc(100%/3)",
                height: 8,
              }}
              aria-hidden={isDisabled}
              aria-label={
                !hasOpenShiftsCount && !hasAgentShiftsCount
                  ? `No ${ShiftPeriodContent[shiftPeriod]} shifts available to book`
                  : `${openShiftsCount} ${ShiftPeriodContent[shiftPeriod]} ${pluralize(
                      "shift",
                      openShiftsCount
                    )} available to book${
                      hasAgentShiftsCount
                        ? ` and ${agentShiftsCount} ${ShiftPeriodContent[shiftPeriod]} ${pluralize(
                            "shift",
                            agentShiftsCount
                          )} booked`
                        : ""
                    }`
              }
            >
              <Span
                sx={{
                  display: "block",
                  height: 4,
                  backgroundColor: hasOpenShiftsCount
                    ? (theme) => theme.palette.info.dark
                    : undefined,
                  filter: hasOpenShiftsCount
                    ? `opacity(${25 + Math.min(openShiftsCount, 3) * 25}%)`
                    : undefined,
                }}
              />
              <Span
                sx={{
                  display: "block",
                  height: 4,
                  // TODO: If we use this variant, we must consider Priority Access shifts which are marked as a seperate color
                  backgroundColor: hasAgentShiftsCount
                    ? (theme) => theme.palette.success.light
                    : undefined,
                  opacity: isDisabled ? 0.5 : 1,
                }}
              />
            </Span>
          );
        })}
      </Stack>
    </CalendarCellItem>
  );
}
