import { type UseModalState } from "@clipboard-health/ui-react";
import { Dialog } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences/types";
import { z } from "zod";

import { DistanceDialogForm } from "./DistanceDialogForm";

type DistanceFieldValues = Pick<AgentPreferences, "distance">;

interface OpenShiftCalendarDistanceFilterDialogProps {
  modalState: UseModalState;
  onSubmit: (values: DistanceFieldValues) => void;
  defaultValues: DistanceFieldValues;
}

export const openShiftCalendarDistanceFilterSchema = z.object({
  distance: z.number(),
});

export function OpenShiftCalendarDistanceFilterDialog(
  props: OpenShiftCalendarDistanceFilterDialogProps
) {
  const { modalState, onSubmit, defaultValues } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog
      fullWidth
      open={modalIsOpen}
      onClose={() => {
        closeModal();
      }}
    >
      <DistanceDialogForm
        defaultValues={defaultValues}
        closeModal={closeModal}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
}
