import { type UseModalState } from "@clipboard-health/ui-react";
import { Dialog } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences/types";
import { type AgentLicenseData } from "@src/appV2/Agents/types";
import { z } from "zod";

import { LicenseDialogForm } from "./LicenseDialogForm";

type LicenseFieldValues = Pick<AgentPreferences, "license">;

interface OpenShiftCalendarLicenseFilterDialogProps {
  modalState: UseModalState;
  onSubmit: (values: LicenseFieldValues) => void;
  defaultValues: LicenseFieldValues;
  agentLicenses: AgentLicenseData[];
}

export const openShiftCalendarLicenseFilterSchema = z.object({
  license: z.string(),
});

export function OpenShiftCalendarLicenseFilterDialog(
  props: OpenShiftCalendarLicenseFilterDialogProps
) {
  const { modalState, onSubmit, defaultValues, agentLicenses } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog
      fullWidth
      open={modalIsOpen}
      onClose={() => {
        closeModal();
      }}
    >
      <LicenseDialogForm
        agentLicenses={agentLicenses}
        defaultValues={defaultValues}
        closeModal={closeModal}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
}
