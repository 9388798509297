import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { OpenShiftCalendarV2CellVariant } from "@src/appV2/FeatureFlags/CbhFeatureFlags";

import { OpenShiftHeatMapCell } from "./HeatMap";
import { OpenShiftV0Cell } from "./ShiftNameCell";
import { type OpenShiftCellProps } from "./types";

const OPEN_SHIFT_CELLS: Record<
  OpenShiftCalendarV2CellVariant,
  (props: OpenShiftCellProps) => JSX.Element
> = {
  [OpenShiftCalendarV2CellVariant.OPEN_SHIFT_V0_CELL]: OpenShiftV0Cell,
  [OpenShiftCalendarV2CellVariant.OPEN_SHIFT_HEAT_MAP_CELL]: OpenShiftHeatMapCell,
};

export function OpenShiftCell(props: OpenShiftCellProps) {
  const openShiftCalendarV2CellVariant = useCbhFlag(
    CbhFeatureFlag.OPEN_SHIFT_CALENDAR_V2_CELL_VARIANTS,
    {
      defaultValue: OpenShiftCalendarV2CellVariant.OPEN_SHIFT_V0_CELL,
    }
  );
  const CellComponent = isDefined(openShiftCalendarV2CellVariant)
    ? OPEN_SHIFT_CELLS[openShiftCalendarV2CellVariant]
    : OpenShiftV0Cell;

  return <CellComponent {...props} />;
}
