import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { shiftSlotSchema } from "../../Facilities/types";

const shiftSlotsRequestSchema = z.object({
  workplaceSlots: z.array(
    z.object({
      workplaceUserId: z.string(),
      range: z.object({
        start: z.string(),
        end: z.string(),
      }),
    })
  ),
});

export type ShiftSlotsRequest = z.infer<typeof shiftSlotsRequestSchema>;

const shiftSlotsResponseSchema = z.array(shiftSlotSchema);

export type ShiftSlotsResponse = z.infer<typeof shiftSlotsResponseSchema>;

const SHIFT_SLOTS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/slots/stats`;

export function useGetShiftSlots(
  params: ShiftSlotsRequest,
  options: UseQueryOptions<ShiftSlotsResponse> = {}
): UseQueryResult<ShiftSlotsResponse> {
  // We cannot use `useGetQuery` here because the API uses POST although
  // it's only used to read data.
  return useQuery({
    queryKey: [SHIFT_SLOTS_URL, params],
    queryFn: async () => {
      const response = await post({
        url: SHIFT_SLOTS_URL,
        data: params,
        requestSchema: shiftSlotsRequestSchema,
        responseSchema: shiftSlotsResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SHIFT_SLOTS_FAILURE,
    },
    ...options,
  });
}
