import { BannerLink } from "@src/appV2/lib/Alert";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { URGENT_SHIFTS_PATH } from "../../paths";

interface UrgentShiftsBannerProps {
  onClose: () => void;
}

export function UrgentShiftsBanner(props: UrgentShiftsBannerProps) {
  const { onClose } = props;

  return (
    <BannerLink
      severity="info"
      to={URGENT_SHIFTS_PATH}
      onClose={onClose}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.TAPPED_URGENT_SHIFTS_BANNER);
      }}
    >
      View Urgent Shifts
    </BannerLink>
  );
}
